import React from "react";
import Configs from "./Configs";
import {getFiltersPath} from "../api";
import {getFiltersFields} from "./config-fields";

export default function Filters(props) {
    return <Configs title='Filters'
                    endpoint={getFiltersPath()}
                    headCells={getFiltersFields()}
                    allowGroupByBank={true}
                    showEnabledBanksOnly={true}
                    {...props}
    />
}