import React, {useContext} from 'react';
import clsx from 'clsx';
import {makeStyles} from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Drawer from '@material-ui/core/Drawer';
import Box from '@material-ui/core/Box';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import Container from '@material-ui/core/Container';
import Link from '@material-ui/core/Link';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import {mainListItems} from "./listItems";
import Brightness4 from "@material-ui/icons/Brightness4";
import Brightness7 from "@material-ui/icons/Brightness7";
import ExitToApp from "@material-ui/icons/ExitToApp";
import {DarkContext} from "../App";
import PrivateRoute from "./PrivateRoute";
import Creditors from "./Creditors";
import DashboardContents from "./DashboardContents";
import Filters from "./Filters";
import Alterations from "./Alterations";
import Tooltip from "@material-ui/core/Tooltip";
import EditFilter from "./EditFilter";
import EditAlteration from "./EditAlteration";
import {ConfirmProvider} from "material-ui-confirm";
import {
    getNewFilterUrl,
    getEditFilterUrl,
    getNewAlterationUrl,
    getEditAlterationUrl, getBankUrl, getNewCreditorUrl, getEditCreditorUrl,
} from "../api";
import {Bank} from "./Bank";
import EditCreditor from "./EditCreditor";


function Copyright() {
    return (
        <Typography variant="body2" color="textSecondary" align="center">
            {'Copyright © '}
            <Link color="inherit" href="https://zensum.se/">
                Zensum AB
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    toolbar: {
        paddingRight: 24, // keep right padding when drawer closed
    },
    toolbarIcon: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: '0 8px',
        ...theme.mixins.toolbar,
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    appBarShift: {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    menuButton: {
        marginRight: 36,
    },
    menuButtonHidden: {
        display: 'none',
    },
    title: {
        flexGrow: 1,
    },
    drawerPaper: {
        position: 'relative',
        whiteSpace: 'nowrap',
        width: drawerWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    drawerPaperClose: {
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.spacing(7),
        [theme.breakpoints.up('sm')]: {
            width: theme.spacing(9),
        },
    },
    appBarSpacer: theme.mixins.toolbar,
    content: {
        flexGrow: 1,
        height: '100vh',
        overflow: 'auto',
    },
    container: {
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
    },
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
    },
    fixedHeight: {
        height: 240,
    },
}));

export default function Dashboard(props) {
    const classes = useStyles();
    const [open, setOpen] = React.useState(true);
    const {dark, setDark} = useContext(DarkContext);

    const handleDrawerOpen = () => {
        setOpen(true);
    };
    const handleDrawerClose = () => {
        setOpen(false);
    };
    const toggleDarkMode = () => {
        setDark(!dark)
    };

    const logOut = () => {
        props.history.push('/logout')
    };

    return (
        <div className={classes.root}>
            <ConfirmProvider>
            <CssBaseline/>
            <AppBar position="absolute" className={clsx(classes.appBar, open && classes.appBarShift)}>
                <Toolbar className={classes.toolbar}>
                    <IconButton
                        edge="start"
                        color="inherit"
                        aria-label="open drawer"
                        onClick={handleDrawerOpen}
                        className={clsx(classes.menuButton, open && classes.menuButtonHidden)}
                    >
                        <MenuIcon/>
                    </IconButton>
                    <Typography component="h1" variant="h6" color="inherit" noWrap className={classes.title}>
                        Applications Manager
                    </Typography>
                    {dark && <Tooltip title={'Enabled light mode'}>
                        <IconButton color="inherit" onClick={toggleDarkMode}>
                            <Brightness7/>
                        </IconButton>
                    </Tooltip>}
                    {!dark && <Tooltip title={'Enabled dark mode'}>
                        <IconButton color="inherit" onClick={toggleDarkMode}>
                            <Brightness4/>
                        </IconButton>
                    </Tooltip>}
                    <Tooltip title={'Log out'}>
                        <IconButton color="inherit" onClick={logOut}>
                            <ExitToApp/>
                        </IconButton>
                    </Tooltip>
                </Toolbar>
            </AppBar>
            <Drawer
                variant="permanent"
                classes={{
                    paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose),
                }}
                open={open}
            >
                <div className={classes.toolbarIcon}>
                    <IconButton onClick={handleDrawerClose}>
                        <ChevronLeftIcon/>
                    </IconButton>
                </div>
                <Divider/>
                <List>{mainListItems}</List>
                {/*<Divider />
                <List>{secondaryListItems}</List>*/}
            </Drawer>
            <main className={classes.content}>
                <div className={classes.appBarSpacer}/>
                <Container maxWidth="lg" className={classes.container}>
                    <PrivateRoute exact path="/" component={DashboardContents}/>
                    <PrivateRoute exact path="/creditors" component={Creditors}/>
                    <PrivateRoute exact path="/filters" component={Filters}/>
                    <PrivateRoute exact path="/alterations" component={Alterations}/>
                    <PrivateRoute path={getBankUrl(':product', ':country', ':bankId')} component={Bank}/>
                    <PrivateRoute exact path={getNewCreditorUrl()} component={EditCreditor}/>
                    <PrivateRoute exact path={getNewFilterUrl()} component={EditFilter}/>
                    <PrivateRoute exact path={getNewAlterationUrl()} component={EditAlteration}/>
                    <PrivateRoute path={getEditCreditorUrl(':config_id')} component={EditCreditor}/>
                    <PrivateRoute path={getEditFilterUrl(':config_id')} component={EditFilter}/>
                    <PrivateRoute path={getEditAlterationUrl(':config_id')} component={EditAlteration}/>
                    <Box pt={4}>
                        <Copyright/>
                    </Box>
                </Container>
            </main>
            </ConfirmProvider>
        </div>
    );
}