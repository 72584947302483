import React, {useEffect, useState} from "react";
import {confirmAlert} from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import * as Utils from "./Utils";
import ReactCountryFlag from "react-country-flag"
import BoolCell from "./BoolCell";
import BoolText from "./BoolText";
import {useAuth0} from "./auth0Provider";
import {getBaseUrl, getHeaders} from "./api";
import {getFilters} from "./data/utils";

const FILTERS_API = getBaseUrl() + 'filters';

const EditFilterButton = props => {
    return <button onClick={props.edit}>Edit</button>
};

const DeleteFilterButton = props => {
    return <button onClick={() => {
        confirmAlert({
            title: 'Confirm filter deletion',
            message: 'Delete filter:' + props.filterDesc + '?',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => props.deleteFilter()
                },
                {
                    label: 'No',
                    onClick: () => {
                    }
                }
            ]
        });
    }
    }>Delete</button>
};

function filterToString(filter) {
    if (!filter) {
        return '';
    }
    return '' + filter['country'] + ' ' + filter['creditorId'] + ' ' + filter['ruleName'] + (filter['creditNoted'] ? ' credit noted' : '');
}

function Filters(props) {
    const [filters, setFilters] = useState([]);
    const [canRead, setCanRead] = useState(false);
    const [canUpdate, setCanUpdate] = useState(false);
    const [canCreate, setCanCreate] = useState(false);
    const [canDelete, setCanDelete] = useState(false);
    const {token, isAuthenticated} = useAuth0();
    const setCanCreateFilter = props.setCanCreateFilter;

    useEffect(() => {
        setCanCreateFilter(canCreate);
    }, [canCreate, setCanCreateFilter]);

    useEffect(() => {
        if (!canRead) {
            return;
        }
        getFilters(token)
            .then(filters => setFilters(filters))

    }, [canRead, token]);

    useEffect(() => {
        if (!isAuthenticated) {
            console.log('Not fetching options - not authenticated')
            return;
        }
        let headers = getHeaders(token);

        console.log('Will fetch options from', FILTERS_API + '/methods');
        fetch(FILTERS_API + '/methods', {
            method: 'OPTIONS',
            headers: headers
        })
            .then(response => {
                if (response.ok) {
                    console.log('Response is', response.status);
                    return response.json();
                }
                throw new Error('Network response was not ok: ' + response.body);
            })
            .then(response => {
                const allowed = response['allow']
                setCanRead(allowed.includes('GET'))
                setCanCreate(allowed.includes('POST'))
                setCanUpdate(allowed.includes('PUT'))
                setCanDelete(allowed.includes('DELETE'))
            })
            .catch(err => console.log('There has been a problem with your fetch operation: ', err.message));
    }, [isAuthenticated, token])

    const onFilterDeleted = (id) => {
        console.log('onFilterDeleted', id);
        const modified_filters = filters.filter(f => f['id'] !== id);
        setFilters(modified_filters);
    }

    const deleteFilterCallback = id => {
        let headers = getHeaders(token);

        fetch(FILTERS_API + '/' + id, {
            method: 'DELETE',
            headers: headers
        }).then(r => {
            console.log('Deleting filter ' + id + " status ", r.status);
            if (r.ok) {
                onFilterDeleted(id);
            }
        })
    };

    const formatFilters = (filters) => {
        let print = Utils.isPrint();
        let product = Utils.country();
        let country = Utils.country();
        let bank_id = Utils.bank_id();
        /*assuming filters are grouped by country and creditor*/

        let objectsToShow = filters.filter(c => (!product || c.product === product) && (!country || c.country === country) && (!bank_id || c.creditorId === bank_id));

        let groupBy = function (xs, key) {
            return xs.reduce(function (rv, x) {
                (rv[x[key]] = rv[x[key]] || []).push(x);
                return rv;
            }, {});
        };
        let groupedByCountry = groupBy(objectsToShow, 'country');
        for (let c in groupedByCountry) {
            groupedByCountry[c] = groupBy(groupedByCountry[c], 'creditorId')
        }

        let returnValues = [];
        for (let c in groupedByCountry) {
            for (let b in groupedByCountry[c]) {
                returnValues.push(<thead>
                <tr>
                    <th colSpan="7"><ReactCountryFlag
                        countryCode={c}/> {Utils.creditorIdToName(props.creditors, b, c)}</th>
                </tr>
                </thead>);
                let rows = [];
                for (const filter of groupedByCountry[c][b]) {
                    rows.push(<tr key={filter['id'] + '-' + filter.ruleName}>
                        <td align="left">{filter.ruleName}{print && ': '}{!print &&
                        <br/>}<small>{Utils.formatArgValues(filter.argValues)}</small></td>
                        <td>{!print && <BoolCell value={filter.creditNoted}/>}{print &&
                        <BoolText value={filter.creditNoted} trueVal='credit noted' falseVal=''/>}</td>
                        <td>{!print && <BoolCell value={filter.hard}/>}{print &&
                        <BoolText value={filter.hard} trueVal='auto+manual' falseVal='auto only'/>}</td>
                        {!Utils.enabledOnly() && <td><BoolCell value={filter.enabled}/></td>}
                        {!print && <span>
                        {canUpdate && <td><EditFilterButton edit={() => {
                            props.edit(filter)
                        }}/></td>}
                            {canDelete && <td><DeleteFilterButton
                                filterDesc={filterToString(filter)}
                                deleteFilter={() => {
                                    deleteFilterCallback(filter['id'])
                                }}/>
                            </td>}</span>
                        }
                    </tr>);
                }
                returnValues.push(<tbody>{rows}</tbody>);
            }
        }
        return returnValues;
    }

    let enabledOnly = Utils.enabledOnly();
    const filtersToDisplay = filters.filter(f => !enabledOnly || f['enabled']);
    return (
        <table id="filters">
            <thead>
            <tr>
                <th>Rule</th>
                <th>Credit noted</th>
                <th>Hard</th>
                {!enabledOnly && <th>Enabled</th>}
                <th/>
                <th/>
            </tr>
            </thead>
            {formatFilters(filtersToDisplay)}
        </table>
    );
}

export default Filters