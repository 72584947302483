import {api, getFiltersPath} from "../api";

export const getData = async (path, token) => {
    console.debug('fetching data from ' + path);
    return api
        .get(path, token)
        .then(x => {
            console.debug(`Got ${x.length} objects from ${path}`);
            return x;
        })
};

export const postData = async (path, data, token) => {
    console.debug('post data', data);
    return api
        .post(path, data, token)
        .then(result => {
            console.debug(`Posted ${data} to ${path} with result ${result}`);
            return result;
        })
};

export const putData = async (path, data, token) => {
    return api
        .put(path, data, token)
        .then(result => {
            console.debug(`Posted ${data} to ${path} with result ${result}`);
            return result;
        })
};

export const deleteConfig = async (path, id, onConfigDeleted, token) => {
    return api
        .delete(path + '/' + id, '', token)
        .then(r => {
            if (r.ok) {
                onConfigDeleted(id);
            }
        })
};

export function getRules(endpoint, product, country, token) {
    let rulesPath = '';
    if (endpoint === getFiltersPath()) {
        rulesPath = 'rules'
    } else {
        rulesPath = 'alterrules'
    }
    const query = `?product=${encodeURIComponent(product)}&country=${encodeURIComponent(country)}`;

    return api
        .get(rulesPath + query, token)
        .then(r => {
            console.debug(`Got ${r.length} rules from ${rulesPath}`);
            return r
        })
}

export const getFilters = (token) => getData('/filters', token);
export const fetchCreditors = (token) => getData('/creditors', token);

export const getPermissions = (path, setCanCreate, setCanRead, setCanUpdate, setCanDelete, token) => {
    return api
        .get(path, token)
        .then(x => {
            console.debug(`Got ${x['allow']} permissions from ${path}`);
            return x;
        })
        .then(response => {
            const allowed = response['allow'];
            setCanRead(allowed.includes('GET'));
            setCanCreate(allowed.includes('POST'));
            setCanUpdate(allowed.includes('PUT'));
            setCanDelete(allowed.includes('DELETE'))
        })
        .catch(err => console.error('There has been a problem with your fetch operation: ', err.message));
}

export const getFiltersPermissions = (setCanCreate, setCanRead, setCanUpdate, setCanDelete, token) =>
    getPermissions('/filters/methods', setCanCreate, setCanRead, setCanUpdate, setCanDelete, token)
export const getAlterationsPermissions = (setCanCreate, setCanRead, setCanUpdate, setCanDelete, token) =>
    getPermissions('/alterations/methods', setCanCreate, setCanRead, setCanUpdate, setCanDelete, token)
export const getCreditorsPermissions = (setCanCreate, setCanRead, setCanUpdate, setCanDelete, token) =>
    getPermissions('/creditors/methods', setCanCreate, setCanRead, setCanUpdate, setCanDelete, token)
