import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import React from "react";
import ListItemText from "@material-ui/core/ListItemText";
import DashboardIcon from '@material-ui/icons/Dashboard';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';
import BarChartIcon from '@material-ui/icons/BarChart';
import {Link} from "react-router-dom";
import {getAlterationsPath, getCreditorsPath, getFiltersPath} from "../api";

export const mainListItems = (
    <div>
        <ListItem button component={Link} to="/">
            <ListItemIcon>
                <DashboardIcon/>
            </ListItemIcon>
            <ListItemText primary="Dashboard"/>
        </ListItem>
            <ListItem button component={Link} to={getCreditorsPath()}>
                <ListItemIcon>
                    <AccountBalanceIcon/>
                </ListItemIcon>
                <ListItemText primary="Creditors"/>
            </ListItem>
        <ListItem button component={Link} to={getFiltersPath()}>
            <ListItemIcon>
                <RemoveCircleOutlineIcon/>
            </ListItemIcon>
            <ListItemText primary="Filters"/>
        </ListItem>
        <ListItem button component={Link} to={getAlterationsPath()}>
            <ListItemIcon>
                <BarChartIcon/>
            </ListItemIcon>
            <ListItemText primary="Alterations"/>
        </ListItem>
    </div>
);