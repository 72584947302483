export function getCreditorsKeyFields() {
    return [{
        id: 'product',
        type: 'list',
        label: 'Product',
        helperText: 'Product for bank integration',
        default: '',
        editable: false,
    }, {
        id: 'country',
        type: 'list',
        label: 'Country',
        helperText: 'Country for bank integration',
        default: '',
        editable: false,
    }]
}

export function getKeyFields() {
    return [{
        id: 'product',
        type: 'list',
        label: 'Product',
        helperText: 'Product for bank integration',
        default: '',
        editable: false,
        dependentFields: ['creditorId', 'ruleName'] // reset those fields to default if value of field changes
    }, {
        id: 'country',
        type: 'list',
        label: 'Country',
        helperText: 'Country for bank integration',
        default: '',
        editable: false,
        dependentFields: ['creditorId', 'ruleName'] // reset those fields to default if value of field changes
    }, {
        id: 'creditorId',
        type: 'list',
        label: 'Bank',
        default: '',
        editable: false,
    },]
}

export function getCreditorsFields() {
    return [
        {id: 'name', numeric: false, align: 'left', type: 'string', disablePadding: false, label: 'Name', default: '', editable: true},
        {id: 'bank_id', numeric: true, align: 'left', type: 'integer', disablePadding: false, label: 'Bank ID', default: 0, editable: false},
        {id: 'auto_invite', numeric: false, align: 'left', type: 'bool', disablePadding: false, label: 'Auto invite', default: false},
        {id: 'manual_invite', numeric: false, align: 'left', type: 'bool', disablePadding: false, label: 'Manual invite', default: false},
        {id: 'comments', numeric: false, align: 'left', type: 'bool', disablePadding: false, label: 'Send comments', default: false, internal: true},
        {id: 'documents', numeric: false, align: 'left', type: 'bool', disablePadding: false, label: 'Send documents', default: false, internal: true},
        {id: 'accept', numeric: false, align: 'left', type: 'bool', disablePadding: false, label: 'Accept', default: false, internal: true},
        {id: 'cancel', numeric: false, align: 'left', type: 'bool', disablePadding: false, label: 'Cancel', default: false, internal: true},
        {id: 'invite_sign', numeric: false, align: 'left', type: 'bool', disablePadding: false, label: 'Invite sign', default: false, internal: true},
        {id: 'allow_offer_update', numeric: false, align: 'left', type: 'bool', disablePadding: false, label: 'Allow offer update', default: false, internal: true},
        {id: 'invite_credit_noted', numeric: false, align: 'left', type: 'bool', disablePadding: false, label: 'Allow credit noted', default: false},
        {id: 'credit_noted_only', numeric: false, align: 'left', type: 'bool', disablePadding: false, label: 'Credit noted only', default: false},
        {id: 'sverker_integration', numeric: false, align: 'left', type: 'bool', disablePadding: false, label: 'Sverker integration', default: false, internal: true},
        {id: 'sverker_traffic_percent', numeric: true, align: 'left', type: 'integer', disablePadding: false, label: 'Percent traffic to sverker', default: 100, internal: true},
        {id: 'destination', numeric: false, align: 'left', type: 'string', disablePadding: false, label: 'Destination id', default: '', internal: true},
        {id: 'destination_url', numeric: false, align: 'left', type: 'string', disablePadding: false, label: 'Destination URL', default: '', internal: true},
        {id: 'bank_issuer', numeric: false, align: 'left', type: 'string', disablePadding: false, label: 'Bank issuer', default: '', internal: true},
        {id: 'resend', numeric: false, align: 'left', type: 'bool', disablePadding: false, label: 'Repeat sending', default: false, internal: true},
        {id: 'pause', numeric: false, align: 'left', type: 'bool', disablePadding: false, label: 'Paused outgoing traffic', default: false, internal: true},
        {id: 'destination_auth_out_header', numeric: false, align: 'left', type: 'string', disablePadding: false, label: 'Destination auth out header', default: false, internal: true},
        {id: 'destination_auth_in', numeric: false, align: 'left', type: 'string', disablePadding: false, label: 'Destination auth in', default: false, internal: true},
        {id: 'enabled', numeric: false, align: 'left', type: 'bool', disablePadding: false, label: 'Enabled', default: true},
        {id: 'actions', numeric: false, align: 'left', type: 'actions', disablePadding: false, label: 'Actions', default: null, disableDelete: true},
        {id: 'mortgage_refinance', numeric: false, align: 'left', type: 'bool', disablePadding: false, label: 'Mortgage refinance', default: false},
        {id: 'mortgage_send', numeric: false, align: 'left', type: 'bool', disablePadding: false, label: 'Send mortgage', default: false},
        {id: 'partner_group', numeric: false, align: 'left', type: 'string', disablePadding: false, label: 'Creditor group', default: false},
        {id: 'ska_months', numeric: true, align: 'left', type: 'integer', disablePadding: false, label: 'SKA months', default: 0, internal: true},
        {id: 'ska2_months', numeric: true, align: 'left', type: 'integer', disablePadding: false, label: 'SKA2 months', default: 0, internal: true},
    ];
}

export function getFiltersFields() {
    return [
        {id: 'ruleName', numeric: false, align: 'left', type: 'list', disablePadding: false, label: 'Rule', default: '', dependentFields: ['argValues']},
        {id: 'argValues', numeric: false, align: 'left', type: 'dict', disablePadding: false, label: 'Values', default: []},
        {id: 'hard', numeric: false, align: 'left', type: 'bool', disablePadding: false, label: 'Manual', default: true, trueVal: 'auto+manual', falseVal: 'auto only'},
        {id: 'creditNoted', numeric: false, align: 'left', type: 'bool', disablePadding: false, label: 'Credit noted', default: false, trueVal: 'credit noted', falseVal: ''},
        {id: 'enabled', numeric: false, align: 'left', type: 'bool', disablePadding: false, label: 'Enabled', default: true},
        {id: 'actions', numeric: false, align: 'left', type: 'actions', disablePadding: false, label: 'Actions', default: null},
    ];
}

export function getAlterationsFields() {
    return [
        {id: 'ruleName', numeric: false, align: 'left', type: 'list', disablePadding: true, label: 'Rule', default: '', dependentFields: ['argValues']},
        {id: 'argValues', numeric: false, align: 'left', type: 'dict', disablePadding: false, label: 'Values', default: []},
        {id: 'hard', numeric: false, align: 'left', type: 'bool', disablePadding: false, label: 'Manual', default: true, trueVal: 'auto+manual', falseVal: 'auto only'},
        {id: 'enabled', numeric: false, align: 'left', type: 'bool', disablePadding: false, default: true, label: 'Enabled'},
        {id: 'actions', numeric: false, align: 'left', type: 'actions', disablePadding: false, default: null, label: 'Actions'},
    ];
}
