import React from "react";
import EditConfig from "./EditConfig";
import {getAlterationsPath, getAltersRulesEndpoint} from "../api";
import {getAlterationsFields, getKeyFields} from "./config-fields";

export default function EditAlteration(props) {
    return <EditConfig
        objectName={'alteration'}
        endpoint={getAlterationsPath()}
        rulesEndpointFunc={getAltersRulesEndpoint}
        returnUrl={getAlterationsPath()}
        fields={getAlterationsFields()}
        keyFields={getKeyFields()}
        allowAllCreditorsValue={true}
        {...props}/>
}