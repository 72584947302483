import React from "react";
import Configs from "./Configs";
import {getAlterationsPath} from "../api";
import {getAlterationsFields} from "./config-fields";


export default function Alterations(props) {
    return <Configs title='Alterations'
                    endpoint={getAlterationsPath()}
                    headCells={getAlterationsFields()}
                    allowGroupByBank={true}
                    showEnabledBanksOnly={true}
                    {...props}
    />
}