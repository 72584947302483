import React, {useEffect} from "react";
import {useAuth0} from "./auth0Provider";
import {getFrontBaseUrl} from "./api";

export default function Logout() {
    const {logout} = useAuth0();
    useEffect(() => {
        logout({
            returnTo: getFrontBaseUrl('/logout')
        });
        /* eslint-disable-next-line react-hooks/exhaustive-deps */
    }, []);
    return (<div>Logged out!</div>)
}