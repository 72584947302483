import React, {useEffect, useState} from "react";
import FormControl from "@material-ui/core/FormControl";
import Input from "@material-ui/core/Input";
import FormHelperText from "@material-ui/core/FormHelperText";
import {fetchCreditors, getData, getRules, postData, putData} from "../data/utils";
import {useAuth0} from "../auth0Provider";
import Paper from "@material-ui/core/Paper";
import {getHeaders} from "../api";
import {Redirect} from "react-router-dom";
import CircularProgress from "@material-ui/core/CircularProgress";
import * as Utils from "../Utils";
import Select from "@material-ui/core/Select";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Button from "@material-ui/core/Button";
import FormGroup from "@material-ui/core/FormGroup";
import NotFound from "./NotFound";
import TextField from "@material-ui/core/TextField";

function emptyObject(fields) {
    return Object.assign({}, ...fields.map((x) => ({[x.id]: x.default})))
}

// noinspection DuplicatedCode
export default function EditConfig(props) {
    // noinspection JSUnresolvedVariable
    const id = props.match.params.config_id;
    const updateMode = !!id;
    const endpoint = props.endpoint;
    const fields = props.fields;
    const keyFields = props.keyFields;
    const allFields = keyFields.concat(fields).filter(f => f['id'] !== 'actions');
    const [creditors, setCreditors] = useState([]);
    const [object, setObject] = useState(updateMode ? {} : emptyObject(allFields));
    const product = updateMode ? (object ? object['product'] : '') : object['product'];
    const country = updateMode ? (object ? object['country'] : '') : object['country'];
    const [rules, setRules] = useState([]);
    const [error, setError] = useState('');
    const [redirect, setRedirect] = useState(null);
    const {token, isAuthenticated} = useAuth0();
    const [fetchingCreditors, setFetchingCreditors] = useState(false);
    const [fetchingObjects, setFetchingObjects] = useState(false);
    const [fetchingRules, setFetchingRules] = useState(false);
    const [submitting, setSubmitting] = useState(false);

    useEffect(() => {
        console.debug('effect get creditors');
        if (!isAuthenticated) return;
        setFetchingCreditors(true);
        fetchCreditors(token).then(creditors => {
            setCreditors(creditors);
            setFetchingCreditors(false)
        }).catch(_ => setFetchingCreditors(false))
    }, [isAuthenticated, token]);

    useEffect(() => {
        console.debug('effect get filter');
        if (!updateMode) return;
        setFetchingObjects(true);
        getData(endpoint, token).then(objects => {
            const object = objects.filter(o => (o['id'] || o['configId']) === id);
            if (object) {
                setObject(object[0])
            } else {
                console.error('Not found object for id ' + id)
            }
            setFetchingObjects(false)
        }).catch(_ => setFetchingObjects(false))
    }, [id, updateMode, endpoint, token]);

    useEffect(() => {
        console.debug('effect get rules');
        if (!product) return;
        if (!country) return;
        setFetchingRules(true);
        getRules(endpoint, product, country, token).then(rules => {
            setRules(rules);
            setFetchingRules(false)
        }).catch(_ => setFetchingRules(false)
        )
    }, [product, country, endpoint, token]);

    if (updateMode && !object) {
        return <NotFound/>
    }

    if (updateMode && Object.keys(object).length === 0) {
        return <CircularProgress/>
    }

    if (redirect) {
        return <Redirect to={redirect}/>
    }

    const getRule = () => {
        return rules.find(rule => rule.name === object.ruleName) || null;
    };

    const getCreditors = () => creditors; // TODO get creditors from cache

    const handleChange = (event) => {
        console.debug('handle change');
        let objectCopy = Object.assign({}, object);
        const target = event.target;

        // handle argValues field
        if (target.name.startsWith("param_")) {
            let param_name = target.name.substring("param_".length);
            let arg = objectCopy.argValues.find(arg => arg['name'] === param_name);
            let newValue;
            switch (target.type) {
                case 'number':
                    newValue = parseInt(target.value);
                    break;
                case 'checkbox': {
                    let list = arg ? arg['value'] : [];
                    if (target.checked) {
                        list.push(target.value);
                        newValue = list;
                    } else {
                        let i = list.indexOf(target.value);
                        list.splice(i, 1);
                        newValue = list;
                    }
                    break;
                }
                default:
                    newValue = target.value;
            }

            if (arg) {
                arg["value"] = newValue;
            } else {
                objectCopy.argValues.push({
                    name: param_name,
                    value: newValue
                })
            }
            setObject(objectCopy);
            return;
        }

        const field = allFields.filter(f => f.id === target.name)[0];
        if (!field) {
            console.error('Not found field for input name: ' + target.name);
            return
        }

        if (field.dependentFields && objectCopy[field.id].toString() !== target.value.toString()) {
            field.dependentFields.forEach(id => {
                const dependentField = allFields.filter(f => f.id === id)[0];
                if (!dependentField) {
                    console.error('dependent field not found', id);
                    return
                }
                if (objectCopy[id] !== undefined) {
                    objectCopy[id] = dependentField.default // reset to default value
                }
            })
        }

        switch (field.type) {
            case 'bool':
                objectCopy[field.id] = target.checked;
                break;
            case 'string':
            case 'list':
                objectCopy[field.id] = target.value.toString();
                break;
            case 'integer':
                objectCopy[field.id] = parseInt(target.value);
                break;
            default:
                console.log('Field', field.id, 'of type', field.type, 'was not processed')
        }

        setObject(objectCopy);
    };

    const castArgsToProperTypes = (object) => {
        if (!object.argValues) {
            return
        }
        for (let i = 0; i < object.argValues.length; i++) {
            let param_name = object.argValues[i]['name'];
            let value = object.argValues[i]['value'];
            let type = getParamType(param_name);
            if (type === 'float') {
                object.argValues[i]['value'] = parseFloat(value);
            }
        }
    };

    const handleSubmit = (event) => {
        let headers = getHeaders(token);
        setError('');
        headers.set('Accept', 'application/json');
        headers.set('Content-Type', 'application/json');

        console.log('Form submitted ', object);
        event.preventDefault();
        castArgsToProperTypes(object);
        let async_obj;
        setSubmitting(true);

        let sendObject = {};
        allFields.forEach(field => {
            if (object[field.id] !== undefined) {
                sendObject[field.id] = object[field.id]
            }
        });
        if (!sendObject['configId']) {
            sendObject['configId'] = object['id'] || object['configId']
        }

        if (updateMode) {
            async_obj = putData(endpoint, sendObject, token)
        } else {
            async_obj = postData(endpoint, sendObject, token)
        }
        async_obj.then(r => {
            if (r.ok) {
                setRedirect(props.returnUrl)
            }
            setSubmitting(false)
        }).catch(err => {
            setSubmitting(false);
            console.log('Error submitting object: ', err);
            try {
                err.json().then(json => {
                    console.debug('setting error ' + json['error']);
                    setError(json['error'])
                });
            } catch (e) {
                console.error('request error ', err)
            }
        })
    };

    const getParams = () => {
        let rule = getRule();
        return (rule === null) ? [] : rule['params'];
    };

    const getParamValue = (name) => {
        let arg = object.argValues.find(arg => arg['name'] === name);
        return arg ? arg['value'] : '';
    };

    const getParamType = (name) => {
        let param = getParams().find(param => param['name'] === name);
        return param ? param['type'] : '';
    };
    const getInputByParamType = (param, ruleName) => {
        if (param["type"] === 'integer') {
            return <Input type="number"
                          name={"param_" + param["name"]}
                          value={getParamValue(param["name"])}
                          onChange={handleChange}/>
        } else if (param["type"] === 'integer') {
            return <Input type="number" step="0.01"
                          name={"param_" + param["name"]}
                          value={getParamValue(param["name"])}
                          onChange={handleChange}/>
        } else if (param["type"] === 'array<string>') {
            let rule = rules.find(rule => rule["name"] === ruleName);
            let ruleParam = rule.params.find(ruleParam => ruleParam["name"] === param["name"]);
            return <FormGroup>
                {ruleParam["values"].map(value => <FormControlLabel key={"param_" + param["name"] + '_' + value}
                    control={<Checkbox name={"param_" + param["name"]} value={value}
                                       checked={getParamValue(param["name"]).includes(value)}
                                       onChange={handleChange}/>}
                    label={value}/>)}
            </FormGroup>
        } else if (param["type"].startsWith('enum:')) {
            let rule = rules.find(rule => rule["name"] === ruleName);
            let ruleParam = rule.params.find(ruleParam => ruleParam["name"] === param["name"]);
            return <select name={"param_" + param["name"]} onChange={handleChange}>
                {!getParamValue(param["name"]) && <option key="_no_selection">Please select value</option>}
                {ruleParam["values"].map(value => <option key={value}
                                                          selected={getParamValue(param["name"]) === value ? "selected" : ""}>{value}</option>)}
            </select>
        } else {
            return <input type="text"
                          name={"param_" + param["name"]}
                          value={getParamValue(param["name"])}
                          onChange={handleChange}/>
        }
    };

    function renderInput(field) {
        switch (field.type) {
            case 'integer':
                const a = updateMode ? {value: object[field.id]} : {};
                return <TextField name={field.id} aria-describedby={"helper-text-" + field.id} label={field.label} type="number"
                                  InputProps={{
                                      readOnly: updateMode && (field.editable === false),
                                  }} onChange={handleChange} {...a}/>;
            case 'string':
                const b = updateMode ? {value: object[field.id]} : {};
                return <TextField name={field.id} aria-describedby={"helper-text-" + field.id} label={field.label}
                                  InputProps={{
                                      readOnly: updateMode && (field.editable === false),
                                  }} onChange={handleChange} {...b}/>;
            case 'list':
                if (field.id === 'product') {
                    let products = Utils.getProducts();
                    if (!products.length) {
                        products = [object[field.id]]
                    }
                    return <Select onChange={handleChange}
                                   value={object[field.id]} name="product">
                        {!id && <option key='' value="">Please select product</option>}
                        {products.map(product => <option key={product}
                                                          value={product}>{product}</option>)}
                    </Select>
                } else if (field.id === 'country') {
                    let countries = Utils.getCountriesFromCreditors(getCreditors());
                    if (!countries.length) {
                        countries = [object[field.id]]
                    }
                    return <Select onChange={handleChange}
                                   value={object[field.id]} name="country">
                        {!id && <option key='' value="">Please select country</option>}
                        {countries.map(country => <option key={country}
                                                          value={country}>{country}</option>)}
                    </Select>
                } else if (field.id === 'creditorId') {
                    let creditors = Utils.getCreditorsForCountry(getCreditors(), object.product, object.country, props.allowAllCreditorsValue);
                    if (!creditors.length) {
                        creditors = [{bank_id: object[field.id], name: '?'}]
                    }
                    return <Select value={object[field.id]}
                                   onChange={handleChange} name="creditorId">
                        {!id && <option key='' value="">Please select creditor</option>}
                        {creditors.map(cred => <option
                            key={cred.bank_id} value={cred.bank_id}>{cred.name} ({cred.bank_id})</option>)}
                    </Select>
                } else if (field.id === 'ruleName') {
                    let _rules = rules;
                    if (!_rules.length) {
                        _rules = [{name: object[field.id]}]
                    }
                    return <Select value={object[field.id]} name="ruleName" onChange={handleChange}>
                        <option key='' value="">Please select rule</option>
                        {_rules.map(rule => <option key={rule['name']}
                                                    value={rule['name']}>{rule['name']}</option>)}
                    </Select>
                } else {
                    console.log.error('unknown list field ' + field.id);
                    break;
                } case 'bool':
                return <FormControlLabel
                    control={<Checkbox checked={object[field.id]} onChange={handleChange} name={field.id}/>}
                    label={field.label}/>;
            case 'dict':
                return getParams().map(param => <div
                    key={"param_" + param["name"]}>{param["name"]}
                    {getInputByParamType(param, object.ruleName)}<br/>
                </div>);
            default:
                return <Input id={field.id} aria-describedby={"helper-text-" + field.id}/>
        }
    }

    const onCancel = () => setRedirect(props.returnUrl);

    return <Paper>
        <h1>{(updateMode ? 'Edit ' : 'Add new ') + props.objectName}</h1>
        <form onSubmit={handleSubmit}><br/>
            {
                allFields.map(field => <div key={field.id}>
                    <FormControl disabled={updateMode && (field.editable === false)}>
                        {/*{field.type !== 'bool' && field.type !== 'dict' &&
                        <InputLabel htmlFor="my-input">{field.label}</InputLabel>}*/}
                        {renderInput(field)}
                        <FormHelperText id={"helper-text-" + field.id}>{field.helperText}</FormHelperText>
                    </FormControl>
                    <br/>
                </div>)
            }
            <Button type="cancel" onClick={onCancel}>Cancel</Button>
            <Button type="submit" variant={'contained'} color={"secondary"}>{updateMode ? 'Update' : 'Create'}</Button>
            <div style={{display: "flex", color: "red"}}><b>{error}</b></div>
        </form>
        {(submitting || fetchingCreditors || fetchingObjects || fetchingRules) && <CircularProgress/>}
    </Paper>
}
