import * as config from './config'

export function getFrontBaseUrl() {
    return config.FRONTEND_URL;
}

export function getBaseUrl() {
    return config.BACKEND_URL + '/api/v1';
}

export const getEditConfigUrl = (endpoint, id) => '/edit' + endpoint + '/' + id
export const getNewConfigUrl = (endpoint) => '/edit' + endpoint
export const getBankUrl = (country, bank_id) => `/bank/${country}/${bank_id}`

export const getEditCreditorUrl = (id) => getEditConfigUrl(getCreditorsPath(), id)
export const getEditFilterUrl = (id) => getEditConfigUrl(getFiltersPath(), id)
export const getEditAlterationUrl = (id) => getEditConfigUrl(getAlterationsPath(), id)
export const getNewCreditorUrl = () => getNewConfigUrl(getCreditorsPath())
export const getNewFilterUrl = () => getNewConfigUrl(getFiltersPath())
export const getNewAlterationUrl = () => getNewConfigUrl(getAlterationsPath())

export const getCreditorsPath = () => '/creditors'
export const getFiltersPath = () => '/filters'
export const getAlterationsPath = () => '/alterations'

export const getFiltersEndpoint = () => getBaseUrl() + getFiltersPath()
export const getAlterationsEndpoint = () => getBaseUrl() + getAlterationsPath()

export const getFiltersRulesEndpoint = (country) => getBaseUrl() + `/rules?country=${encodeURIComponent(country)}`
export const getAltersRulesEndpoint = (country) => getBaseUrl() + `/alterrules?country=${encodeURIComponent(country)}`


const getUrl = (path) =>
    getBaseUrl().replace(/\/$/, "") + "/" + path.replace(/^\//, "");

export function setAuthHeader(headers, token) {
    headers.set('Authorization', 'Bearer ' + token);
}

export function getHeaders(token, json = false) {
    if (!token) {
        console.log('No token!')
    }
    let headers = new Headers();
    setAuthHeader(headers, token)
    if (json) {
        headers.set('Content-Type', 'application/json')
    }
    return headers;
}

const handleResponse = async (res) => {
    if (res.ok) {
        if (res.status === 204 || res.status === 201) {
            return res
        }
        return /application\/json/.test(res.headers.get('content-type')) ?
            res.json() :
            res.text();
    } else
        throw res
};

export const api = {
    options: async (path, token) =>
        fetch(getUrl(path), {
            method: "OPTIONS",
            headers: getHeaders(token),
        }).then(handleResponse),
    get: async (path, token) =>
        fetch(getUrl(path), {
            method: "GET",
            headers: getHeaders(token),
        }).then(handleResponse),
    post: async (path, body, token) =>
        fetch(getUrl(path), {
            method: "POST",
            headers: getHeaders(token, true),
            body: JSON.stringify(body),
        }).then(handleResponse),
    put: async (path, body, token) =>
        fetch(getUrl(path), {
            method: "PUT",
            headers: getHeaders(token, true),
            body: JSON.stringify(body),
        }).then(handleResponse),
    delete: async (path, body, token) =>
        fetch(getUrl(path), {
            method: "DELETE",
            headers: getHeaders(token),
            body: JSON.stringify(body),
        }).then(handleResponse),
};
