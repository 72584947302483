import * as React from "react";
import * as Utils from './Utils';
import {useAuth0} from "./auth0Provider";
import {useEffect, useState} from "react";
import {getBaseUrl, getHeaders} from "./api";

const FILTERS_API = getBaseUrl() + 'filters';

function emptyFilter() {
    return {
        product: '',
        country: '',
        creditorId: '',
        enabled: true,
        creditNoted: false,
        hard: false,
        ruleName: '',
        argValues: []
    }
}

const fetchCountryRules = (product, country, setRules, token) => {
    if (!product) {
        return;
    }
    if (!country) {
        return;
    }
    let headers = getHeaders(token);

    fetch(getBaseUrl() + `rules?product=${encodeURIComponent(product)}&country=${encodeURIComponent(country)}`, {
        method: 'GET',
        headers: headers
    })
        .then(response => {
            if (response.ok) {
                console.log('Response for getting rules is', response.status);
                return response.json();
            }
            throw new Error('Network response was not ok: ' + response.body);
        })
        .then(data => {
            setRules(data);
        })
        .catch(err => console.log('There has been a problem with your fetch operation: ', err.message));
};

function FilterForm(props) {
    const props_filter = props.filter;
    const [filter, setFilter] = useState(props.filter ? props.filter : emptyFilter());
    const [error, setError] = useState("");
    const [rules, setRules] = useState([]);
    const {token} = useAuth0();

    useEffect(() => {
        console.log('componentDidUpdate');
        setFilter(props_filter ? props_filter : emptyFilter());
        setError("");
    }, [props_filter]);


    const getRule = () => {
        return rules.find(rule => rule.name === filter.ruleName) || null;
    };

    const getParams = () => {
        let rule = getRule();
        return (rule === null) ? [] : rule['params'];
    };

    const getParamValue = (name) => {
        let arg = filter.argValues.find(arg => arg['name'] === name);
        return arg ? arg['value'] : '';
    };

    const getParamType = (name) => {
        let param = getParams().find(param => param['name'] === name);
        return param ? param['type'] : '';
    };

    const getInputByParamType = (param, ruleName) => {
        if (param["type"] === 'integer') {
            return <input type="number"
                          name={"param_" + param["name"]}
                          value={getParamValue(param["name"])}
                          onChange={handleChange}/>
        } else if (param["type"] === 'integer') {
            return <input type="number" step="0.01"
                          name={"param_" + param["name"]}
                          value={getParamValue(param["name"])}
                          onChange={handleChange}/>
        } else if (param["type"] === 'array<string>') {
            let rule = rules.find(rule => rule["name"] === ruleName);
            let ruleParam = rule.params.find(ruleParam => ruleParam["name"] === param["name"]);
            return <fieldset>
                {ruleParam["values"].map(value => <div key={value}>
                    <input type="checkbox" name={"param_" + param["name"]} value={value}
                           defaultChecked={getParamValue(param["name"]).includes(value)}
                           onChange={handleChange}/>{value}
                </div>)}
            </fieldset>;
        } else if (param["type"].startsWith('enum:')) {
            let rule = rules.find(rule => rule["name"] === ruleName);
            let ruleParam = rule.params.find(ruleParam => ruleParam["name"] === param["name"]);
            return <select name={"param_" + param["name"]} onChange={handleChange}>
                {!getParamValue(param["name"]) && <option key="_no_selection">Please select value</option>}
                {ruleParam["values"].map(value => <option key={value}
                                                          selected={getParamValue(param["name"]) === value ? "selected" : ""}>{value}</option>)}
            </select>
        } else {
            return <input type="text"
                          name={"param_" + param["name"]}
                          value={getParamValue(param["name"])}
                          onChange={handleChange}/>
        }
    };

    useEffect(() => {
        fetchCountryRules(filter.product, filter.country, setRules, token);
    }, [filter.product, filter.country, token]);

    const handleChange = (event) => {
        let filterCopy = Object.assign({}, filter);
        const target = event.target;
        if (target.name.startsWith("param_")) {
            let param_name = target.name.substring("param_".length);
            let arg = filterCopy.argValues.find(arg => arg['name'] === param_name);
            let newValue;
            switch (target.type) {
                case 'number':
                    newValue = parseInt(target.value);
                    break;
                case 'checkbox': {
                    let list = arg ? arg['value'] : [];
                    if (target.checked) {
                        list.push(target.value);
                        newValue = list;
                    } else {
                        let i = list.indexOf(target.value);
                        list.splice(i, 1);
                        newValue = list;
                    }
                    break;
                }
                default:
                    newValue = target.value;
            }

            if (arg) {
                arg["value"] = newValue;
            } else {
                filterCopy.argValues.push({
                    name: param_name,
                    value: newValue
                })
            }
            setFilter(filterCopy);
            return;
        }
        switch (target.name) {
            case 'product':
                if (filterCopy.country) {
                    filterCopy.creditorId = "NO_CREDITOR";
                    filterCopy.ruleName = 'NO_RULE';
                    filterCopy.product = target.value;
                    fetchCountryRules(filterCopy.product, filterCopy.country, setRules, token);
                }
                break;
            case 'country':
                if (filterCopy.product) {
                    filterCopy.creditorId = "NO_CREDITOR";
                    filterCopy.ruleName = 'NO_RULE';
                    filterCopy.country = target.value;
                    fetchCountryRules(filterCopy.product, filterCopy.country, setRules, token);
                }
                break;
            case 'creditorId':
                filterCopy.creditorId = target.value;
                break;
            case 'enabled':
                filterCopy.enabled = target.checked;
                break;
            case 'creditNoted':
                filterCopy.creditNoted = target.checked;
                break;
            case 'hard':
                filterCopy.hard = target.checked;
                break;
            case 'ruleName':
                if (filterCopy.ruleName !== target.value) {
                    filterCopy.ruleName = target.value;
                    filterCopy.argValues = [];
                }
                break;
            default:
                console.error('Unknown value updated', target.name)
        }
        setFilter(filterCopy);
    };

    const castArgsToProperTypes = (filter) => {
        for (let i = 0; i < filter.argValues.length; i++) {
            let param_name = filter.argValues[i]['name'];
            let value = filter.argValues[i]['value'];
            let type = getParamType(param_name);
            if (type === 'float') {
                filter.argValues[i]['value'] = parseFloat(value);
            }
        }
    };

    const handleSubmit = (event) => {
        let headers = getHeaders(token);
        headers.set('Accept', 'application/json');
        headers.set('Content-Type', 'application/json');

        console.log('Form submitted: ', filter);
        const method = props['filter'] ? 'PUT' : 'POST';
        event.preventDefault();
        castArgsToProperTypes(filter);
        fetch(FILTERS_API, {
            method: method,
            headers: headers,
            body: JSON.stringify(filter),
        }).then(r => {
            console.log('Result of sending filter', r.status);
            if (!r.ok) {
                r.json().then(json => setError(json['error']));
            } else {
                window.location.reload();
            }
        }).catch(err => {
            console.log('Error submitting filter: ', err);
        });
    };

    return <form action={FILTERS_API} method="post" onSubmit={handleSubmit}><br/>
        <div style={{display: "flex"}}>Product: <select onChange={handleChange}
                                                        value={filter.product} name="product">
            {!props.product && <option key="NO_PRODUCT" value="">Please select product</option>}
            {Utils.getProducts().map(product => <option key={product}
                                                                                     value={product}>{product}</option>)}
        </select></div>
        <div style={{display: "flex"}}>Country: <select onChange={handleChange}
                                                        value={filter.country} name="country">
            {!props.filter && <option key="NO_COUNTRY" value="">Please select country</option>}
            {Utils.getCountriesFromCreditors(props.creditors).map(country => <option key={country}
                                                                                     value={country}>{country}</option>)}
        </select></div>
        <div style={{display: "flex"}}>Creditor: <select value={filter.creditorId}
                                                         onChange={handleChange} name="creditorId">
            {!props.filter && <option key="NO_CREDITOR" value="">Please select creditor</option>}
            {Utils.getCreditorsForCountry(props.creditors, filter.product, filter.country, true).map(cred => <option
                key={cred.bank_id} value={cred.bank_id}>{cred.name} ({cred.bank_id})</option>)}
        </select></div>
        <div style={{display: "flex"}}>Rule name:
            <select value={filter.ruleName} name="ruleName" onChange={handleChange}>
                <option key="NO_RULE" value="">Please select rule</option>
                {rules.map(rule => <option key={rule['name']}
                                           value={rule['name']}>{rule['name']}</option>)}
            </select></div>
        <div style={{display: "flex"}}>{getParams().map(param => <div
            key={"param_" + param["name"]}>{param["name"]}
            {getInputByParamType(param, filter.ruleName)}<br/>
        </div>)}</div>
        <div style={{display: "flex"}}>Enabled: <input type="checkbox" checked={filter.enabled}
                                                       onChange={handleChange}
                                                       name="enabled"/></div>
        <div style={{display: "flex"}}>Credit noted: <input type="checkbox" checked={filter.creditNoted}
                                                            onChange={handleChange}
                                                            name="creditNoted"/></div>
        <div style={{display: "flex"}}>Hard: <input type="checkbox" checked={filter.hard}
                                                    onChange={handleChange}
                                                    name="hard"/></div>
        <div style={{display: "flex"}}><input type="submit" value={props.filter ? 'Update' : 'Create'}/></div>
        <div style={{display: "flex", color: "red"}}><b>{error}</b></div>
    </form>
}

export default FilterForm