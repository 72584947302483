import React from "react";
import {getCreditorsPath} from "../api";
import Configs from "./Configs";
import {getCreditorsFields} from "./config-fields";

export default function Creditors(props) {
    return <Configs title='Creditors'
                    endpoint={getCreditorsPath()}
                    headCells={getCreditorsFields()}
                    allowGroupByBank={false}
                    showEnabledBanksOnly={false}
                    {...props}
    />
}