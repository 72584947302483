import React, {Component} from "react";
import ReactCountryFlag from "react-country-flag"
import BoolCell from "./BoolCell";
import * as Utils from "./Utils";

class Creditors extends Component {
    render() {
        let country = Utils.country()
        return <table id="creditors">
            <thead>
            <tr>
                <th>Country</th>
                <th>Bank ID</th>
                <th>Bank name</th>
                <th>Credit noted</th>
                <th>Credit noted only</th>
                <th>Auto invite</th>
                <th>Manual invite</th>
            </tr>
            </thead>
            <tbody>
            {this.props.creditors.filter(c => !country || c.country === country).map(creditor => {
                    // console.log('creditor ', creditor);
                    return <tr key={creditor.country + "_" + creditor.bank_id}>
                        <td><ReactCountryFlag countryCode={creditor.country} title={creditor.country}/></td>
                        <td>{creditor.bank_id}</td>
                        <td align="left">{creditor.name}</td>
                        <td><BoolCell value={creditor.invite_credit_noted}/></td>
                        <td><BoolCell value={creditor.credit_noted_only}/></td>
                        <td><BoolCell value={creditor.auto_invite}/></td>
                        <td><BoolCell value={creditor.manual_invite}/></td>
                    </tr>
                }
            )}
            </tbody>
        </table>
    }
}

export default Creditors