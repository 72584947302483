import React from "react";
import EditConfig from "./EditConfig";
import {getFiltersPath, getFiltersRulesEndpoint} from "../api";
import {getFiltersFields, getKeyFields} from "./config-fields";

export default function EditFilter(props) {
    return <EditConfig
        objectName={'filter'}
        endpoint={getFiltersPath()}
        rulesEndpointFunc={getFiltersRulesEndpoint}
        returnUrl={getFiltersPath()}
        fields={getFiltersFields()}
        keyFields={getKeyFields()}
        allowAllCreditorsValue={true}
        {...props}/>
}