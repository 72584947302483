import React from 'react';
import {Router, Switch, Route} from "react-router-dom";
import {QueryParamProvider} from "use-query-params";
import history from "./utils/history";
import './App.css';
import Main from "./Main";
import PrivateRoute from "./components/PrivateRoute";
import Logout from "./Logout";
import Dashboard from "./components/Dashboard";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import {createMuiTheme, ThemeProvider} from '@material-ui/core/styles';
import CssBaseline from "@material-ui/core/CssBaseline";
import usePersistedState from "./utils/persistent-state";

export const DarkContext = React.createContext({
    dark: false,
    setDark: () => {
    }
})

export default function App() {
    const [dark, setDark] = usePersistedState('dark-mode', useMediaQuery('(prefers-color-scheme: dark)'));
    const value = {dark, setDark};

    const theme = React.useMemo(
        () =>
            createMuiTheme({
                palette: {
                    type: dark ? 'dark' : 'light',
                },
            }),
        [dark],
    );


    return (
        <ThemeProvider theme={theme}>
            <DarkContext.Provider value={value}>
                <CssBaseline/>
                <Router history={history}>
                    <QueryParamProvider ReactRouterRoute={Route}>
                        <div className="App">
                            <Switch>
                                <PrivateRoute path="/logout" component={Logout}/>
                                <PrivateRoute path="/old" component={Main}/>
                                <PrivateRoute path="/" component={Dashboard}/>
                            </Switch>
                        </div>
                    </QueryParamProvider>
                </Router>
            </DarkContext.Provider>
        </ThemeProvider>
    );
}