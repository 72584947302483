const { NODE_ENV }  = process.env;
export function getCurrentUrl() {
    let path = window.location.href.split('?')[0];
    return path.split('/').slice(0, 3).join('/')
}

export function defaultBackendUrl() {
    let path = getCurrentUrl()
    if (process.env.NODE_ENV === 'development') {
        path = path.replace(':3000', ':9191')
    }
    return path;
}
export const JWK_AUDIENCE = NODE_ENV === 'development' ? process.env.REACT_APP_JWK_AUDIENCE : window.REACT_APP_JWK_AUDIENCE;
export const JWK_ISSUER = NODE_ENV === 'development' ? process.env.REACT_APP_JWK_ISSUER : window.REACT_APP_JWK_ISSUER;
export const CLIENT_ID = NODE_ENV === 'development' ? process.env.REACT_APP_CLIENT_ID : window.REACT_APP_CLIENT_ID;
export const BACKEND_URL = NODE_ENV === 'development' ? (process.env.REACT_APP_BACKEND_URL || defaultBackendUrl()) : (window.REACT_APP_BACKEND_URL || defaultBackendUrl());
export const FRONTEND_URL = NODE_ENV === 'development' ? (process.env.REACT_APP_FRONTEND_URL || getCurrentUrl()) : (window.REACT_APP_FRONTEND_URL || getCurrentUrl());
