import React, {useEffect, useState} from "react";
import {useAuth0} from "../auth0Provider";
import EnhancedTable from "./EnhancedTable";
import {deleteConfig, fetchCreditors, getData, getPermissions} from "../data/utils";
import * as Utils from "../Utils";
import CircularProgress from "@material-ui/core/CircularProgress";
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import usePersistedState from "../utils/persistent-state";
import Button from "@material-ui/core/Button";
import {getEditConfigUrl, getNewConfigUrl} from "../api";

function isCreditorEnabled(creditors, product, country, bank_id) {
    for (let i in creditors) {
        if (!creditors.hasOwnProperty(i)) {
            continue
        }
        const creditor = creditors[i];
        if (Number(creditor.bank_id) === Number(bank_id) && creditor.country === country && creditor.product === product) {
            return creditor.enabled
        }
    }
    return false
}

// noinspection JSUnusedLocalSymbols
export default function Configs(props) {
    const showSwitches = props.showSwitches === undefined ? true : props.showSwitches;
    const allowGroupByBank = props.allowGroupByBank === undefined ? false : props.allowGroupByBank;
    const showEnabledBanksOnly = props.showEnabledBanksOnly === undefined ? false : props.showEnabledBanksOnly;
    const showTitle = props.showTitle;
    const propsProduct = props.match.params.product;
    const propsCountry = props.match.params.country;
    const propsBankId = props.match.params.bankId;
    const [creditors, setCreditors] = useState(null);
    const [objects, setObjects] = useState(null);
    const [canRead, setCanRead] = useState(false);
    const [canUpdate, setCanUpdate] = useState(false);
    const [canCreate, setCanCreate] = useState(false);
    const [canDelete, setCanDelete] = useState(false);
    const [enabledBanksOnly, setEnabledBanksOnly] = usePersistedState('enabled-banks-only', true);
    const [enabledOnly, setEnabledOnly] = usePersistedState('enabled-only', true);
    const [groupByBank, setGroupByBank] = usePersistedState('grouped-by-bank', true);
    const {token, isAuthenticated} = useAuth0();
    const [product,] = useState(propsProduct ? propsProduct : Utils.product());
    const [country,] = useState(propsCountry ? propsCountry : Utils.country());
    const [bank_id,] = useState(propsBankId ? propsBankId : Utils.bank_id());
    let headCells = props.headCells;
    const actionField = headCells.filter(c => c.id === 'actions');
    const disableDelete = actionField && actionField[0].disableDelete;
    const endpoint = props.endpoint;

    useEffect(() => {
        if (!isAuthenticated) return;
        fetchCreditors(token).then(creditors => {
            setCreditors(creditors)
        })
    }, [isAuthenticated, token]);

    useEffect(() => {
        if (!isAuthenticated) return;
        getPermissions(endpoint + '/methods', setCanCreate, setCanRead, setCanUpdate, setCanDelete, token).then(r => r)
    }, [endpoint, isAuthenticated, token]);

    useEffect(() => {
        if (!canRead) return;
        getData(endpoint, token).then(objects => setObjects(objects))
    }, [endpoint, canRead, token]);

    const onConfigDeleted = (id) => {
        const modified_objects = objects.filter(f => f['id'] !== id);
        setObjects(modified_objects);
    };

    const shouldGroupByBank = () => allowGroupByBank && groupByBank;

    const setActiveBanksOnlyClick = () => setEnabledBanksOnly(!enabledBanksOnly);
    const setEnabledOnlyClick = () => setEnabledOnly(!enabledOnly);
    const setGroupByBankClick = () => setGroupByBank(!groupByBank);

    function editClick(object) {
        props.history.push(getEditConfigUrl(endpoint, object.key))
    }

    function doDelete(object) {
        console.debug('delete ' + JSON.stringify(object));
        if (!canDelete) return;
        deleteConfig(endpoint, object['key'], onConfigDeleted, token).then(r => r)
    }

    function addClick() {
        props.history.push(getNewConfigUrl(endpoint))
    }

    if (!objects || !creditors) {
        return <CircularProgress/>
    }

    let objectsToShow = objects.filter(c => (!product || c.product === product) && (!country || c.country === country) && (!bank_id || c.creditorId === bank_id));
    if (showEnabledBanksOnly && enabledBanksOnly) {
        objectsToShow = objectsToShow.filter(o => isCreditorEnabled(creditors, o.product, o.country, o.creditorId))
    }

    // remove internal fields from main view
    headCells = headCells.filter(item => !item.internal);

    if (enabledOnly) {
        headCells = headCells.filter(item => item.id !== 'enabled')
    }

    if (!(canDelete && !disableDelete) && !canUpdate) {
        headCells = headCells.filter(item => item.id !== 'actions')
    }

    if (!shouldGroupByBank()) {
        const filtered = headCells.filter(i => i.id === 'countryBank');
        if (filtered.length === 0) {
            headCells.unshift({
                id: 'countryBank',
                numeric: false,
                align: 'left',
                type: 'string',
                disablePadding: true,
                label: 'Bank'
            })
        }
    }

    let data = [];
    let prev = {bank: null, product: null, country: null};
    let bankObjects = {title: props.title, rows: []};
    for (let o of objectsToShow) {
        if (!o['enabled'] && enabledOnly) {
            continue
        }
        let cur = {bank: o['creditorId'], product: o['product'], country: o['country']};
        if (JSON.stringify(cur) !== JSON.stringify(prev) && shouldGroupByBank()) {
            prev = cur;
            if (bankObjects.rows.length !== 0) {
                data.push(bankObjects)
            }
            bankObjects = {title: Utils.creditorIdToName(creditors, o['creditorId'], o['product'], o['country']), rows: []}
        }
        let row = {key: o['id'] || o['configId']};
        if (!shouldGroupByBank()) {
            row['countryBank'] = Utils.creditorIdToName(creditors, o['creditorId'], o['product'], o['country'])
        }
        for (const item of headCells) {
            const columnName = item.id;
            if (o[columnName] !== undefined) {
                row[columnName] = o[columnName]
            }
        }
        row['delete_description'] = `product=${o['product']} country=${o['country']} bank_id=${o['creditorId']} rule=${o['ruleName']}`;
        bankObjects.rows.push(row)
    }
    if (bankObjects.rows.length !== 0) {
        data.push(bankObjects)
    }

    let returnValues = [];
    returnValues.push(<h1>{props.title}</h1>);
    if (canCreate) {
        returnValues.push(<Button variant="contained" color="primary" onClick={addClick}>Add</Button>)
    }
    for (const item of data) {
        returnValues.push(<EnhancedTable headCells={headCells} rows={item.rows}
                                         title={shouldGroupByBank() && showTitle !== false ? item.title : undefined}
                                         onEditClicked={canUpdate ? editClick : undefined}
                                         onDeleteClicked={canDelete && !disableDelete ? doDelete : undefined}/>)
    }
    if (showSwitches) {
        if (showEnabledBanksOnly) {
            returnValues.push(<FormControlLabel
                control={<Switch checked={enabledBanksOnly} onChange={setActiveBanksOnlyClick}/>}
                label="Enabled banks only"
            />)
        }
        returnValues.push(<FormControlLabel
            control={<Switch checked={enabledOnly} onChange={setEnabledOnlyClick}/>}
            label="Enabled only"
        />);
        if (allowGroupByBank) {
            returnValues.push(<FormControlLabel
                control={<Switch checked={groupByBank} onChange={setGroupByBankClick}/>}
                label="Group by bank"
            />)
        }
    }
    return returnValues;
}