import React, { useEffect } from "react";
import { Route } from "react-router-dom";
import { useAuth0 } from "../auth0Provider";

const PrivateRoute = ({ component: Component, path, location, ...rest }) => {
    const { loading, isAuthenticated, loginWithRedirect } = useAuth0();

    useEffect(() => {
        if (loading || isAuthenticated) {
            return;
        }
        const fn = async () => {
            const targetUrl = `${location.pathname}${location.search}`
            await loginWithRedirect({
                appState: { targetUrl }
            });
        };
        fn();
    }, [ loading, isAuthenticated, loginWithRedirect, location ]);


    const render = props =>
        isAuthenticated === true ? <Component {...props} /> : null;

    return <Route path={path} render={render} {...rest} />;
};

export default PrivateRoute;