import React, {useEffect, useState} from "react";
import {confirmAlert} from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import * as Utils from "./Utils";
import ReactCountryFlag from "react-country-flag"
import BoolCell from "./BoolCell";
import {useAuth0} from "./auth0Provider";
import {getBaseUrl, getHeaders} from "./api";

const ALTERATIONS_API = getBaseUrl() + 'alterations';

const EditAlterationButton = props => {
    return <button onClick={props.edit}>Edit</button>
};

const DeleteAlterationButton = props => {
    return <button onClick={() => {
        confirmAlert({
            title: 'Confirm alteration deletion',
            message: 'Delete alteration:' + props.alterationDesc + '?',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => props.deleteAlteration()
                },
                {
                    label: 'No',
                    onClick: () => {
                    }
                }
            ]
        });
    }
    }>Delete</button>
};

function alterationToString(alteration) {
    if (!alteration) {
        return '';
    }
    return '' + alteration['product'] + ' ' + alteration['country'] + ' ' + alteration['creditorId'] + ' ' + alteration['ruleName'];
}

function Alterations(props) {
    const [alterations, setAlterations] = useState([]);
    const [canRead, setCanRead] = useState(false);
    const [canUpdate, setCanUpdate] = useState(false);
    const [canCreate, setCanCreate] = useState(false);
    const [canDelete, setCanDelete] = useState(false);
    const {token, isAuthenticated} = useAuth0();
    const setCanCreateAlteration = props.setCanCreateAlteration;

    useEffect(() => {
        setCanCreateAlteration(canCreate);
    }, [canCreate, setCanCreateAlteration]);

    useEffect(() => {
        if (!canRead) {
            return;
        }
        let headers = getHeaders(token);

        console.log('Will fetch from', ALTERATIONS_API);
        fetch(ALTERATIONS_API, {
            method: 'GET',
            headers: headers
        })
            .then(response => {
                if (response.ok) {
                    console.log('Response is', response.status);
                    return response.json();
                }
                throw new Error('Network response was not ok: ' + response.body);
            })
            .then(json => {
                //console.log('Received json: ', json);
                return json
            })
            .then(data => setAlterations(data))
            .catch(err => console.log('There has been a problem with your fetch operation: ', err.message));
    }, [canRead, token]);

    useEffect(() => {
        if (!isAuthenticated) {
            console.log('Not fetching options - not authenticated');
            return;
        }
        let headers = getHeaders(token);

        console.log('Will fetch options from', ALTERATIONS_API + '/methods');
        fetch(ALTERATIONS_API + '/methods', {
            method: 'OPTIONS',
            headers: headers
        })
            .then(response => {
                if (response.ok) {
                    console.log('Response is', response.status);
                    return response.json();
                }
                throw new Error('Network response was not ok: ' + response.body);
            })
            .then(response => {
                const allowed = response['allow'];
                setCanRead(allowed.includes('GET'));
                setCanCreate(allowed.includes('POST'));
                setCanUpdate(allowed.includes('PUT'));
                setCanDelete(allowed.includes('DELETE'))
            })
            .catch(err => console.log('There has been a problem with your fetch operation: ', err.message));
    }, [isAuthenticated, token]);

    const onAlterationDeleted = (id) => {
        console.log('onAlterationDeleted', id);
        const modified_alterations = alterations.filter(f => f['id'] !== id);
        setAlterations(modified_alterations);
    };

    const deleteAlterationCallback = id => {
        let headers = getHeaders(token);

        fetch(ALTERATIONS_API + '/' + id, {
            method: 'DELETE',
            headers: headers
        }).then(r => {
            console.log('Deleting alteration ' + id + " status ", r.status);
            if (r.ok) {
                onAlterationDeleted(id);
            }
        })
    };

    const formatAlterations = (alterations) => {
        let print = Utils.isPrint();
        let product = Utils.product();
        let country = Utils.country();
        let bank_id = Utils.bank_id();
        /*assuming alterations are grouped by country and creditor*/

        let objectsToShow = alterations.filter(c => (!product || c.product === product) && (!country || c.country === country) && (!bank_id || c.creditorId === bank_id));

        let groupBy = function (xs, key) {
            return xs.reduce(function (rv, x) {
                (rv[x[key]] = rv[x[key]] || []).push(x);
                return rv;
            }, {});
        };
        let groupedByCountry = groupBy(objectsToShow, 'country');  // TODO group by product
        for (let c in groupedByCountry) {
            groupedByCountry[c] = groupBy(groupedByCountry[c], 'creditorId')
        }

        let returnValues = [];
        for (let c in groupedByCountry) {
            for (let b in groupedByCountry[c]) {
                returnValues.push(<thead>
                <tr>
                    <th colSpan="5"><ReactCountryFlag
                        countryCode={c}/> {Utils.creditorIdToName(props.creditors, b, c)}</th>
                </tr>
                </thead>);
                let rows = [];
                for (const alteration of groupedByCountry[c][b]) {
                    rows.push(<tr key={alteration['id'] + '-' + alteration.ruleName}>
                        <td align="left">{alteration.ruleName}{print && ': '}{!print &&
                        <br/>}<small>{Utils.formatArgValues(alteration.argValues)}</small></td>
                        {!Utils.enabledOnly() && <td><BoolCell value={alteration.enabled}/></td>}
                        {!print && <span>
                        {canUpdate && <td><EditAlterationButton edit={() => {
                            props.edit(alteration)
                        }}/></td>}
                            {canDelete && <td><DeleteAlterationButton
                                alterationDesc={alterationToString(alteration)}
                                deleteAlteration={() => {
                                    deleteAlterationCallback(alteration['id'])
                                }}/>
                            </td>}</span>
                        }
                    </tr>);
                }
                returnValues.push(<tbody>{rows}</tbody>);
            }
        }
        return returnValues;
    }

    let enabledOnly = Utils.enabledOnly();
    const alterationsToDisplay = alterations.filter(f => !enabledOnly || f['enabled']);
    return (
        <table id="alterations">
            <thead>
            <tr>
                <th>Rule</th>
                {!enabledOnly && <th>Enabled</th>}
                <th/>
                <th/>
            </tr>
            </thead>
            {formatAlterations(alterationsToDisplay)}
        </table>
    );
}

export default Alterations