import React from "react";
import EditConfig from "./EditConfig";
import {getCreditorsPath} from "../api";
import {getCreditorsFields, getCreditorsKeyFields} from "./config-fields";

export default function EditCreditor(props) {
    return <EditConfig
        objectName={'creditor'}
        endpoint={getCreditorsPath()}
        returnUrl={getCreditorsPath()}
        fields={getCreditorsFields()}
        keyFields={getCreditorsKeyFields()}
        allowAllCreditorsValue={false}
        {...props}/>
}