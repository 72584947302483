import React from "react";
import Checkbox from "@material-ui/core/Checkbox";

export function isPrint() {
    const urlParams = new URLSearchParams(window.location.search);
    return urlParams.has('print');
}

export function enabledOnly() {
    const urlParams = new URLSearchParams(window.location.search);
    return urlParams.get('enabled_only') === 'true';
}

export function product() {
    const urlParams = new URLSearchParams(window.location.search);
    return urlParams.get('product');
}

export function country() {
    const urlParams = new URLSearchParams(window.location.search);
    return urlParams.get('country');
}

export function bank_id() {
    const urlParams = new URLSearchParams(window.location.search);
    return urlParams.get('bank_id');
}

export function creditorIdToName(creditors, creditorId, product, country, withFlag=true) {
    if (creditorId === 'ALL_CREDITORS') {
        return 'All creditors';
    }
    let name = '';
    let filtered = creditors.filter(c => c['bank_id'].toString() === creditorId.toString() && c['country'] === country && c['product'] === product);
    if (filtered && filtered[0]) {
        name = filtered[0]['name'];
    }
    return (country === 'SE' ? '🇸🇪' : '🇳🇴') + ' ' +  (name ? name : '?')+ ' (' + filtered[0]['product'].substr(0, 2).toUpperCase() + " " + creditorId + ')';
}

export function nonClickableCheckbox(checked) {
    return <Checkbox disabled checked={checked} onClick={() => {return false;}} readOnly />
}

export function getProducts() {
    return ['blanco', "mortgage"];
}

export function getProductsFromCreditors(creditors) {
    let products = new Set();
    if (!creditors) {
        return Array.from(products);
    }
    for (let i=0; i<creditors.length; i++) {
        products.add(creditors[i]['product']);
    }
    return Array.from(products);
}

export function getCountriesFromCreditors(creditors) {
    let countries = new Set();
    if (!creditors) {
        return Array.from(countries);
    }
    for (let i=0; i<creditors.length; i++) {
        countries.add(creditors[i]['country']);
    }
    return Array.from(countries);
}

export function getCreditorsForCountry(creditors, product, country, all_countries = false) {
    let result = creditors.filter(c => c['country'] === country && c['product'] === product);
    if (all_countries && result.length > 0) {
        result.unshift({'bank_id': 'ALL_CREDITORS', 'name': 'All creditors in ' + country + ' for ' + product})
    }
    return result;
}

export function formatArgValues(argValues) {
    let result = '';
    let separator = '';
    for (let i=0; i<argValues.length; i++) {
        result += separator + argValues[i]['name'] + '=' + argValues[i]['value'];
        separator = ', ';
    }
    return result;
}
