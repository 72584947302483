import React, {useEffect} from 'react';

export default function usePersistedState(key, defaultValue) {
    const [state, setState] = React.useState(
        () => {
            const value = JSON.parse(localStorage.getItem(key))
            return value !== null ? value : defaultValue
        }
    );
    useEffect(() => {
        localStorage.setItem(key, state);
    }, [key, state]);
    return [state, setState];
}