import React, {useEffect, useState} from "react";
import LogoutButton from "../LogoutButton";
import Creditors from "../Creditors";
import Filters from "../Filters";
import Alterations from "../Alterations";
import FilterForm from "../FilterForm";
import AlterationForm from "../AlterationForm";
import {useAuth0} from "../auth0Provider";
import {getBaseUrl, getHeaders} from "../api";
import * as config from '../config';

const CREDITORS_API = getBaseUrl() + 'creditors';
const views = {
    CREDITORS: 'creditors',
    FILTERS: 'filters',
    FILTERS_FORM: 'filters_form',
    ALTERATIONS: 'alterations',
    ALTERATIONS_FORM: 'alterations_form'
};

const AddFilterButton = props => {
    return <button disabled={props.disabled} onClick={props.addFilter}>Add new filter</button>
};

const AddAlterationButton = props => {
    return <button disabled={props.disabled} onClick={props.addAlteration}>Add new alteration</button>
};

const ShowCreditorsButton = props => {
    return <button disabled={props.disabled} onClick={props.showCreditors}>Creditors</button>
};

const ShowFiltersButton = props => {
    return <button disabled={props.disabled} onClick={props.showFilters}>Filters</button>
};

const ShowAlterationButton = props => {
    return <button disabled={props.disabled} onClick={props.showAlterations}>Alterations</button>
};

// noinspection JSUnusedLocalSymbols
function Main(props) {
    const [currentView, setCurrentView] = useState(views.FILTERS);
    const [editFilter, setEditFilter] = useState(null);
    const [editAlteration, setEditAlteration] = useState(null);
    const [creditors, setCreditors] = useState([]);
    const {isAuthenticated, loginWithRedirect} = useAuth0();
    const [canCreateFilter, setCanCreateFilter] = useState(false);
    const [canCreateAlteration, setCanCreateAlteration] = useState(false);


    const setAddFilterState = () => {
        setCurrentView(views.FILTERS_FORM);
        setEditFilter(null);
    };

    const setAddAlterationState = () => {
        setCurrentView(views.ALTERATIONS_FORM);
        setEditAlteration(null);
    };

    const editFilterCallback = filter => {
        console.log("Editing filter", filter);
        setCurrentView(views.FILTERS_FORM);
        setEditFilter(filter);
    };

    const editAlterationCallback = alteration => {
        console.log("Editing alteration", alteration);
        setCurrentView(views.ALTERATIONS_FORM);
        setEditAlteration(alteration);
    };

    const {token} = useAuth0();

    useEffect(() => {
        if (!isAuthenticated) {
            console.log('Not authenticated');
            return;
        }
        let headers = getHeaders(token);
        console.log('TOKEN is ' + token);

        fetch(CREDITORS_API, {
            method: 'GET',
            headers: headers
        })
            .then(response => {
                if (response.ok) {
                    console.log('Response is', response.status);
                    return response.json();
                }
                throw new Error('Network response was not ok: ' + response.body);
            })
            .then(json => {
                return json
            })
            .then(data => setCreditors(data))
            .catch(err => console.log('There has been a problem with your fetch operation: ', err.message));
    }, [isAuthenticated, token]);

    console.log('client id ' + config.CLIENT_ID);
    return (
        <div>
            {isAuthenticated && (<div className="App">
                <div style={{display: "flex"}}>
                    <ShowCreditorsButton
                        style={{marginLeft: "auto"}}
                        showCreditors={() => {
                            setCurrentView(views.CREDITORS);
                        }}
                        disabled={currentView === views.CREDITORS}
                    />
                    <ShowFiltersButton
                        style={{marginLeft: "auto"}}
                        showFilters={() => {
                            setCurrentView(views.FILTERS);
                        }}
                        disabled={currentView === views.FILTERS}
                    />
                    <ShowAlterationButton
                        style={{marginLeft: "auto"}}
                        showAlterations={() => {
                            setCurrentView(views.ALTERATIONS);
                        }}
                        disabled={currentView === views.ALTERATIONS}
                    />
                    {currentView === views.FILTERS && canCreateFilter && <AddFilterButton addFilter={setAddFilterState}/>}
                    {currentView === views.ALTERATIONS && canCreateAlteration &&
                    <AddAlterationButton addAlteration={setAddAlterationState}/>}
                    <LogoutButton/>
                </div>

                {currentView === views.CREDITORS &&
                <Creditors creditors={creditors.filter(c => c['enabled'] === true)}/>}
                {currentView === views.FILTERS && <Filters creditors={creditors} edit={editFilterCallback} setCanCreateFilter={setCanCreateFilter}/>}
                {currentView === views.ALTERATIONS && <Alterations creditors={creditors} edit={editAlterationCallback} setCanCreateAlteration={setCanCreateAlteration}/>}
                {currentView === views.FILTERS_FORM &&
                <FilterForm creditors={creditors} filter={editFilter}/>}
                {currentView === views.ALTERATIONS_FORM &&
                <AlterationForm creditors={creditors} alteration={editAlteration}/>}
            </div>)}
            {!isAuthenticated && (<a href="/" onClick={() => loginWithRedirect({})}>Log in</a>)}
        </div>
    );
}

export default Main;