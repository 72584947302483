import * as React from "react";
import * as Utils from './Utils';
import {useAuth0} from "./auth0Provider";
import {useEffect, useState} from "react";
import {getBaseUrl, getHeaders} from "./api";

const ALTERATIONS_API = getBaseUrl() + 'alterations';

function emptyAlteration() {
    return {
        product: '',
        country: '',
        creditorId: '',
        enabled: true,
        hard: true,
        ruleName: '',
        argValues: []
    }
}

const fetchCountryRules = (product, country, setRules, token) => {
    if (!country) {
        return;
    }
    if (!product) {
        return;
    }
    let headers = getHeaders(token);

    fetch(getBaseUrl() + `alterrules?product=${encodeURIComponent(product)}country=${encodeURIComponent(country)}`, {
        method: 'GET',
        headers: headers
    })
        .then(response => {
            if (response.ok) {
                console.log('Response for getting rules is', response.status);
                return response.json();
            }
            throw new Error('Network response was not ok: ' + response.body);
        })
        .then(data => {
            setRules(data);
        })
        .catch(err => console.log('There has been a problem with your fetch operation: ', err.message));
};

function AlterationForm(props) {
    const props_alteration = props.alteration;
    const [alteration, setAlteration] = useState(props.alteration ? props.alteration : emptyAlteration());
    const [error, setError] = useState("");
    const [rules, setRules] = useState([]);
    const {token} = useAuth0();

    useEffect(() => {
        setAlteration(props_alteration ? props_alteration : emptyAlteration());
        setError("");
    }, [props_alteration]);

    const getRule = () => {
        return rules.find(rule => rule.name === alteration.ruleName) || null;
    };

    const getParams = () => {
        let rule = getRule();
        return (rule === null) ? [] : rule['params'];
    };

    const getParamValue = (name) => {
        let arg = alteration.argValues.find(arg => arg['name'] === name);
        return arg ? arg['value'] : '';
    };

    const getParamType = (name) => {
        let param = getParams().find(param => param['name'] === name);
        return param ? param['type'] : '';
    };

    const getInputByParamType = (param, ruleName) => {
        if (param["type"] === 'integer') {
            return <input type="number"
                          name={"param_" + param["name"]}
                          value={getParamValue(param["name"])}
                          onChange={handleChange}/>
        } else if (param["type"] === 'integer') {
            return <input type="number" step="0.01"
                          name={"param_" + param["name"]}
                          value={getParamValue(param["name"])}
                          onChange={handleChange}/>
        } else if (param["type"] === 'array<string>') {
            let rule = rules.find(rule => rule["name"] === ruleName);
            let ruleParam = rule.params.find(ruleParam => ruleParam["name"] === param["name"]);
            return <fieldset>
                {ruleParam["values"].map(value => <div key={value}>
                    <input type="checkbox" name={"param_" + param["name"]} value={value}
                           defaultChecked={getParamValue(param["name"]).includes(value)}
                           onChange={handleChange}/>{value}
                </div>)}
            </fieldset>;
        } else if (param["type"].startsWith('enum:')) {
            let rule = rules.find(rule => rule["name"] === ruleName);
            let ruleParam = rule.params.find(ruleParam => ruleParam["name"] === param["name"]);
            return <select name={"param_" + param["name"]} onChange={handleChange}>
                {!getParamValue(param["name"]) && <option key="_no_selection">Please select value</option>}
                {ruleParam["values"].map(value => <option key={value}
                                                          selected={getParamValue(param["name"]) === value ? "selected" : ""}>{value}</option>)}
            </select>
        } else {
            return <input type="text"
                          name={"param_" + param["name"]}
                          value={getParamValue(param["name"])}
                          onChange={handleChange}/>
        }
    };

    useEffect(() => {
        fetchCountryRules(alteration.product, alteration.country, setRules, token);
    }, [alteration.product, alteration.country, token]);

    const handleChange = (event) => {
        let alterationCopy = Object.assign({}, alteration);
        const target = event.target;
        if (target.name.startsWith("param_")) {
            let param_name = target.name.substring("param_".length);
            let arg = alterationCopy.argValues.find(arg => arg['name'] === param_name);
            let newValue;
            switch (target.type) {
                case 'number':
                    newValue = parseInt(target.value);
                    break;
                case 'checkbox': {
                    let list = arg ? arg['value'] : [];
                    if (target.checked) {
                        list.push(target.value);
                        newValue = list;
                    } else {
                        let i = list.indexOf(target.value);
                        list.splice(i, 1);
                        newValue = list;
                    }
                    break;
                }
                default:
                    newValue = target.value;
            }

            if (arg) {
                arg["value"] = newValue;
            } else {
                alterationCopy.argValues.push({
                    name: param_name,
                    value: newValue
                })
            }
            setAlteration(alterationCopy);
            return;
        }
        switch (target.name) {
            case 'product':
                if (alterationCopy.country) {
                    alterationCopy.creditorId = "NO_CREDITOR";
                    alterationCopy.ruleName = 'NO_RULE';
                    alterationCopy.product = target.value;
                    fetchCountryRules(alterationCopy.product, alterationCopy.country, setRules, token);
                }
                break;
            case 'country':
                if (alterationCopy.product) {
                    alterationCopy.creditorId = "NO_CREDITOR";
                    alterationCopy.ruleName = 'NO_RULE';
                    alterationCopy.country = target.value;
                    fetchCountryRules(alterationCopy.product, alterationCopy.country, setRules, token);
                }
                break;
            case 'creditorId':
                alterationCopy.creditorId = target.value;
                break;
            case 'enabled':
                alterationCopy.enabled = target.checked;
                break;
            case 'hard':
                alterationCopy.hard = target.checked;
                break;
            case 'ruleName':
                if (alterationCopy.ruleName !== target.value) {
                    alterationCopy.ruleName = target.value;
                    alterationCopy.argValues = [];
                }
                break;
            default:
                console.error('Unknown value updated', target.name)
        }
        setAlteration(alterationCopy);
    };

    const castArgsToProperTypes = (alteration) => {
        console.log(alteration);
        for (let i = 0; i < alteration.argValues.length; i++) {
            console.log(alteration[i]);
            let param_name = alteration.argValues[i]['name'];
            let value = alteration.argValues[i]['value'];
            let type = getParamType(param_name);
            if (type === 'float') {
                alteration.argValues[i]['value'] = parseFloat(value);
            }
        }
    };

    const handleSubmit = (event) => {
        let headers = getHeaders(token);
        headers.set('Accept', 'application/json');
        headers.set('Content-Type', 'application/json');

        console.log('Form submitted: ', alteration);
        const method = props['alteration'] ? 'PUT' : 'POST';
        event.preventDefault();
        castArgsToProperTypes(alteration);
        fetch(ALTERATIONS_API, {
            method: method,
            headers: headers,
            body: JSON.stringify(alteration),
        }).then(r => {
            console.log('Result of sending alteration', r.status);
            if (!r.ok) {
                r.json().then(json => setError(json['error']));
            } else {
                window.location.reload();
            }
        }).catch(err => {
            console.log('Error submitting alteration: ', err);
        });
    };

    return <form action={ALTERATIONS_API} method="post" onSubmit={handleSubmit}><br/>
        <div style={{display: "flex"}}>Product: <select onChange={handleChange}
                                                        value={alteration.product} name="product">
            {!props.alteration && <option key="NO_PRODUCT" value="">Please select product</option>}
            {Utils.getProducts().map(product => <option key={product}
                                                                                     value={product}>{product}</option>)}
        </select></div>
        <div style={{display: "flex"}}>Country: <select onChange={handleChange}
                                                        value={alteration.country} name="country">
            {!props.alteration && <option key="NO_COUNTRY" value="">Please select country</option>}
            {Utils.getCountriesFromCreditors(props.creditors).map(country => <option key={country}
                                                                                          value={country}>{country}</option>)}
        </select></div>
        <div style={{display: "flex"}}>Creditor: <select value={alteration.creditorId}
                                                         onChange={handleChange} name="creditorId">
            {!props.alteration && <option key="NO_CREDITOR" value="">Please select creditor</option>}
            {Utils.getCreditorsForCountry(props.creditors, alteration.product, alteration.country).map(cred => <option
                key={cred.bank_id} value={cred.bank_id}>{cred.name} ({cred.bank_id})</option>)}
        </select></div>
        <div style={{display: "flex"}}>Rule name:
            <select value={alteration.ruleName} name="ruleName" onChange={handleChange}>
                <option key="NO_RULE" value="">Please select rule</option>
                {rules.map(rule => <option key={rule['name']}
                                                      value={rule['name']}>{rule['name']}</option>)}
            </select></div>
        <div style={{display: "flex"}}>{getParams().map(param => <div
            key={"param_" + param["name"]}>{param["name"]}
            {getInputByParamType(param, alteration.ruleName)}<br/>
        </div>)}</div>
        <div style={{display: "flex"}}>Enabled: <input type="checkbox" checked={alteration.enabled}
                                                       onChange={handleChange}
                                                       name="enabled"/></div>
        <div style={{display: "flex"}}><input type="hidden" checked={alteration.hard}
                                              onChange={handleChange}
                                              name="hard"/></div>
        <div style={{display: "flex"}}><input type="submit" value={props.alteration ? 'Update' : 'Create'}/>
        </div>
        <div style={{display: "flex", color: "red"}}><b>{error}</b></div>
    </form>
}

export default AlterationForm