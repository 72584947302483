import Paper from "@material-ui/core/Paper";
import EnhancedTableToolbar from "./EnhancedTableToolbar";
import React from "react";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import EnhancedTableHead from "./EnhancedTableHead";
import TableBody from "@material-ui/core/TableBody";
import {makeStyles} from "@material-ui/core/styles";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import Checkbox from "@material-ui/core/Checkbox";
import TablePagination from "@material-ui/core/TablePagination";
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import BoolCell from "../BoolCell";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import Tooltip from "@material-ui/core/Tooltip";
import { useConfirm } from 'material-ui-confirm';
import {formatArgValues} from "../Utils";

function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    paper: {
        width: '100%',
        marginBottom: theme.spacing(2),
    },
    table: {
        minWidth: 750,
    },
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1,
    },
}));

export default function EnhancedTable(props) {
    const headCells = props.headCells
    const firstColumnKey = headCells[0]['id']
    const classes = useStyles();
    const [order, setOrder] = React.useState('asc');
    const [orderBy, setOrderBy] = React.useState(firstColumnKey);
    const [selected, setSelected] = React.useState([]);
    const [page, setPage] = React.useState(0);
    const [dense, setDense] = React.useState(true);
    const [rowsPerPage, setRowsPerPage] = React.useState(0);
    const confirm = useConfirm();
    const rows = props.rows;

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelected = rows.map((n) => n.name);
            setSelected(newSelected);
            return;
        }
        setSelected([]);
    };

    const handleClick = (event, name) => {
        if (!props.selection) {
            return
        }
        const selectedIndex = selected.indexOf(name);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, name);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }

        setSelected(newSelected);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleChangeDense = (event) => {
        setDense(event.target.checked);
    };

    const isSelected = (name) => selected.indexOf(name) !== -1;

    const emptyRows = rowsPerPage === 0 ? 0 : rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

    const onDeleteClicked = (row) => {
        const confirmButtonProps = {color: "secondary", variant: "contained"}
        confirm({ description: 'This action will delete object: ' + row['delete_description'],
            cancellationButtonProps: confirmButtonProps })
            .then(() => { props.onDeleteClicked(row) })
            .catch(() => { /* ... */ });
    };

    return (
        <div className={classes.root}>
            <Paper className={classes.paper}>
                <EnhancedTableToolbar numSelected={selected.length} title={props.title}/>
                <TableContainer>
                    <Table
                        className={classes.table}
                        aria-labelledby="tableTitle"
                        size={dense ? 'small' : 'medium'}
                        aria-label="enhanced table"
                    >
                        <EnhancedTableHead
                            classes={classes}
                            numSelected={selected.length}
                            order={order}
                            orderBy={orderBy}
                            onSelectAllClick={handleSelectAllClick}
                            onRequestSort={handleRequestSort}
                            rowCount={rows.length}
                            headCells={headCells}
                        />
                        <TableBody>
                            {stableSort(rows, getComparator(order, orderBy))
                                .slice(rowsPerPage === 0 ? 0 : page * rowsPerPage, rowsPerPage === 0 ? rows.length : page * rowsPerPage + rowsPerPage)
                                .map((row, index) => {
                                    const isItemSelected = isSelected(row.name);
                                    const labelId = `enhanced-table-checkbox-${index}`;

                                    return (
                                        <TableRow
                                            hover
                                            onClick={(event) => handleClick(event, row.name)}
                                            role="checkbox"
                                            aria-checked={props.selection && isItemSelected}
                                            tabIndex={-1}
                                            key={row.key}
                                            selected={isItemSelected}
                                        >
                                            <TableCell padding="checkbox">
                                                {props.selection && <Checkbox
                                                    checked={isItemSelected}
                                                    inputProps={{'aria-labelledby': labelId}}
                                                />}
                                            </TableCell>
                                            {/*<TableCell component="th" id={labelId} scope="row" padding="none">
                                                {row.name}
                                            </TableCell>*/}
                                            {headCells.map((headCell,) => {
                                                if (headCell.type === 'bool') {
                                                    return <TableCell key={headCell.id}
                                                                      align={headCell.align}><BoolCell
                                                        value={row[headCell.id]} trueVal={headCell.trueVal}
                                                        falseVal={headCell.falseVal}/></TableCell>
                                                } else if (headCell.type === 'dict') {
                                                    return <TableCell key={headCell.id}
                                                                      align={headCell.align}>{formatArgValues(row[headCell.id])}</TableCell>
                                                } else if (headCell.type === 'actions') {
                                                    return <div>
                                                        {props.onEditClicked && <Tooltip title="Edit">
                                                            <IconButton aria-label="edit"
                                                                        onClick={() => props.onEditClicked(row)}>
                                                                <EditIcon/>
                                                            </IconButton>
                                                        </Tooltip>}
                                                        {props.onDeleteClicked && <Tooltip title="Delete">
                                                            <IconButton aria-label="delete"
                                                                        onClick={() => onDeleteClicked(row)}>
                                                                <DeleteIcon/>
                                                            </IconButton>
                                                        </Tooltip>}
                                                    </div>
                                                } else {
                                                    return <TableCell key={headCell.id}
                                                                      align={headCell.align}>{row[headCell.id]}</TableCell>
                                                }
                                            })}
                                        </TableRow>
                                    );
                                })}
                            {emptyRows > 0 && (
                                <TableRow style={{height: (dense ? 33 : 53) * emptyRows}}>
                                    <TableCell colSpan={6}/>
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
                {props.pagination && <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={rows.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                />}
            </Paper>
            {props.paddingSwitch && <FormControlLabel
                control={<Switch checked={dense} onChange={handleChangeDense}/>}
                label="Dense padding"
            />}
        </div>
    );
}

EnhancedTable.defaultProps = {
    selection: false,
    pagination: false,
    paddingSwitch: false,
}